<template>
  <screen_width_cont>
    <!-- <div class="cont_300N"> -->
    <div class="cont_300_basico ancho_acotado">
      <!-- fjecha atrás -->
      <div class="header_top">
        <headerCerrar @cancelar="cerrarProceso" colorIcon="gris" />
      </div>

      <!-- título principal -->
      <div class="autenticaTitle title">
        {{ t("app.autentificacionRegistro.tit") }}
      </div>

      <!-- texto -->
      <div class="autenticaText text_basico f_w_400">
        {{ t("app.autentificacionRegistro.texto") }}&nbsp;
        {{ remitente.correo }}
      </div>

      <!-- label código -->
      <div class="autenticaCodigo">
        {{ t("app.autentificacionRegistro.codigo") }}
      </div>

      <!-- input del código estilo mach -->
      <inputCodigoAutenticacion
        @revisarCodigoComp="revisarCodigoLocal"
        @tieneError="tieneErrorLocal"
        :liberar_campos="!procesando_codigo"
      />
      <div class="errorCodigoLabel">
        <span v-if="tieneError">{{
          t("app.autentificacionRegistro.errorInvalido")
        }}</span>
      </div>
      <!-- {{ respuesta }} -->
      <div class="autenticaLoginResendedCod">
        <!-- <span v-show="codigoResended">{{ t("app.autentificacionRegistro.reSendCod") }}</span>  -->
      </div>

      <!-- volver a generar código -->
      <div class="autenticaResend text_basico f_w_500">
        <span @click="reSendCodigo" class="clickable">{{
          t("app.autentificacionRegistro.regenerarCodigo")
        }}</span>
      </div>

      <!-- problemas para iniciar sesión -->
      <!-- <div class="autenticaProblems">
        <div class="autenticaProblemsText">
          {{ t("app.autentificacionRegistro.problemasCodigo") }}&nbsp;<span @click="levantarModalLoginError"
            class="autenticaProblemsBold">{{ t("app.autentificacionRegistro.ayuda") }}</span>
        </div>
      </div> -->
      <div class="autenticaRegEtapas">
        <pasosGiro paso="2" totales="4" />
      </div>
    </div>

    <!-- <div class="autenticaRegEtapas"><pasosGiro paso="2" totales="4" /></div> -->
  </screen_width_cont>
</template>


<script>
import {
  alertController,
  modalController,
  // IonButton, IonModal,
} from "@ionic/vue";
import headerCerrar from "../components/generales/headerCerrar.vue";
import { useI18n } from "vue-i18n";
import { mapActions, mapMutations, mapState } from "vuex";
import inputCodigoAutenticacion from "../components/generales/inputCodigoAuteticacion.vue";
import pasosGiro from "../components/generales/pasosProceso.vue";
import estas_seguro from "./modales/estas_seguro.vue";
import screen_width_cont from "../components/menuSp/screen_width_cont.vue";

export default {
  setup() {
    const { t } = useI18n();
    return { t };
  },
  components: {
    headerCerrar,
    inputCodigoAutenticacion,
    pasosGiro,
    screen_width_cont,
  },
  data() {
    return {
      tieneError: false,
      title: "",
      subTitle: "",
      buttons: [],
      codigoResended: false,
      borrarDatos: false,
      procesando_codigo: false,
      respuesta: "",
      en_vista: false,
    };
  },
  computed: {
    ...mapState([
      "remitente",
      "resp_validar_codigo_registro",
      "widthScreen",
      "plataforma",
      "url_base_web",
      "idiomaPreferencia",
    ]),
  },
  methods: {
    ...mapActions([
      "validar_codigo_registro",
      "signOff",
      "re_send_cod_registro",
    ]),
    ...mapMutations([
      "limpiarRemitente",
      "updateBloquearNavegacion",
      "dropRemitenteTemp",
      "setshowSpinner",
    ]),

    async callModal() {
      this.updateBloquearNavegacion(true);
      const modal = await modalController.create({
        component: estas_seguro,
        componentProps: {
          title: this.t("app.recoverPassForm.tit_abandonar"),
          color_title: "rojo",
          color_btn_1: "rojo",
          color_btn_2: "",
          text: "",
          btnText_1: this.t("app.comunes.btns.salir"),
          btnText_2: this.t("app.comunes.btns.volver"),
          colorIcon: "rojoOscuro",
        },
        cssClass:
          this.widthScreen < 600
            ? [
                "myModalAbandonar",
                "myModalAbandonar_ancho_80",
                "myModalAbandonar_alto_50",
              ]
            : [
                "myModalAbandonar",
                "myModalAbandonar_ancho_40",
                "myModalAbandonar_alto_40",
              ],
        // showBackdrop: false,
        animated: true,
        backdropDismiss: false,
        // swipeToClose: true,
        // mode: "ios",
      });

      modal.onDidDismiss().then((data) => {
        this.updateBloquearNavegacion(false);
        if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_1"
        ) {
          if (this.plataforma == "web") {
            window.location.replace(
              `${this.url_base_web}/${this.idiomaPreferencia.cod}/`
            );
          } else {
            this.$router.push({ name: "logIn", replace: true });
          }
          this.signOff();
          // this.$router.push({ name: "logIn", replace: true });
        } else if (
          data &&
          data.data &&
          data.data.action &&
          data.data.action == "action_2"
        ) {
          // this.$router.push({ name: "nuevoDestinatarioForm" });
        }
      });
      return modal.present();
    },
    // cerrarAlertModal(){
    //   this.canDismiss = true;
    //   setTimeout(() => {
    //     this.$refs.modal.$el.dismiss();
    //   }, 50);

    // },
    // abandonarProcedimiento(){
    //   this.cerrarAlertModal();
    //   this.limpiarRemitente({
    //     ultimas_transacciones: [],
    //   });
    //   this.$router.push({ name: "logIn", replace: true });
    // },
    cerrarProceso() {
      this.en_vista = false;
      this.callModal();
    },
    revisarCodigoLocal(codigo) {
      this.procesando_codigo = true;
      this.setshowSpinner(true);
      this.validar_codigo_registro(codigo);
    },

    reSendCodigo() {
      this.tieneError = false;
      this.codigoResended = true;

      this.re_send_cod_registro();

      this.subTitle = this.t("app.comunes.autenticacion");
      this.buttons = ["Ok"];
      this.presentAlert();
    },
    async presentAlert() {
      let alert = await alertController.create({
        // header: this.title,
        subHeader: this.subTitle,
        backdropDismiss: false,
        buttons: [
          {
            text: `Ok`,
            role: "cancel",
            cssClass: "customBtnClassAutentica",
          },
        ],
      });
      await alert.present();
    },
  },
  watch: {
    resp_validar_codigo_registro() {
      this.tieneError = false;
      if (this.resp_validar_codigo_registro.status && this.en_vista) {
        this.respuesta = this.resp_validar_codigo_registro.msg;

        this.setshowSpinner(false);
        if (this.resp_validar_codigo_registro.resp) {
          this.tieneError = false;
          this.borrarDatos = !this.borrarDatos;
          // this.$router.push({ name: "registroFormInicial", replace: true });
          this.$router.push({ name: "registroDisclaimer", replace: true });
        } else {
          this.tieneError = true;
        }
        this.procesando_codigo = false;
      }
    },
  },
  mounted() {
    // console.log(this.remitente);
    this.en_vista = true;
  },
  ionViewWillLeave() {
    this.en_vista = false;
    this.codigoResended = false;
    this.borrarDatos = !this.borrarDatos;
    if (
      !this.resp_validar_codigo_registro.status ||
      !this.resp_validar_codigo_registro.resp
    ) {
      this.dropRemitenteTemp();
      this.limpiarRemitente({});
      this.signOff();
    }
  },
};
</script>

<style>
.autenticaTitle {
  color: var(--customA-801B1C);
  margin: 3rem auto 0 auto;
  text-align: center;
}

.autenticaText {
  text-align: justify;
  color: var(--customG-787878);
  margin-top: 2.3rem;
  /* text-align: center;
  text-justify: inter-word; */
  padding: 0.2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.autenticaTextBold {
  font-weight: 500;
  color: var(--customH-4C4C4C);
}

.autenticaCodigo {
  margin-top: 4rem;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 16.41px;
  color: var(--customA-801B1C);
  text-align: center;
}

.autenticaCodigoInput {
  margin-top: 1rem;
  font-size: 0.823rem;
  font-weight: 700;
  line-height: 16.41px;
  color: var(--customA-801B1C);
  align-content: center;
  /* border:  1px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-content: center; */
  align-items: center;
}

.autenticaCodigoInput > div {
  display: flex;
  width: fit-content;
  /* border:  1px solid green; */
}

.autenticaCodigoInput > div > div {
  width: 2rem;
  border-bottom: 1.4px solid var(--customG-787878);
  margin: 0 0.2rem 0 0.2rem;
  display: flex;
  justify-content: center;
  /* border: 1px solid blue; */
}

.autenticaCodigoInput > div > div > input {
  font-size: 2rem;
  font-weight: 700;
  line-height: 28.13px;
  color: var(--customC-614AD3);
  width: 2rem;
  /* border: 1px solid green; */
  padding-left: 0.3rem;
}

.autenticaResend {
  /* font-family: "Roboto";
  font-style: normal;
  line-height: 13px;
  letter-spacing: 0.5px; */
  text-decoration-line: underline;
  margin-top: 2.5rem;
  display: flex;
  justify-content: center;
  /* font-size: 0.6875rem;
  font-weight: 700; */
  color: var(--customG-787878);
}

.autenticaProblems {
  margin-top: 2rem;
  position: relative;
  /* bottom: 2rem;
  width: 70%; */
}

.autenticaProblemsText {
  font-size: 0.6875rem;
  font-weight: 500;
  line-height: 12.89px;
  color: var(--customH-4C4C4C);
  display: flex;
  justify-content: center;
}

.autenticaProblemsBold {
  font-weight: 700;
  color: var(--customC-614AD3);
}

.customBtnClassAutentica {
  font-size: 0.5rem;
  font-weight: 500;
  line-height: 9.38px;
  color: var(--customH-4C4C4C) !important;
}

.alert-sub-title {
  font-size: 0.5rem;
  font-weight: 700;
  line-height: 18.38px;
  color: var(--customC-614AD3) !important;
  /* border: 1px solid green; */
}

.autenticaLoginResendedCod {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 0.6875rem;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.5px;
  margin-top: 2.5rem;
  color: var(--customC-614AD3);
}

ion-modal#example-modal {
  --width: fit-content;
  --min-width: 200px;
  --max-width: 250px;
  --height: fit-content;
  --box-shadow: 0px 1px 11px 2px rgba(0, 0, 0, 0.15);
  --border-radius: 20px;
}

ion-modal#example-modal .wrapper {
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
}

.alertModalCerrar {
  margin-top: 0.2rem;
}

.alertModalTitle {
  margin-top: 3rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 1px;
  color: #801b1c;
}

.alertModalText {
  margin-top: 1.7rem;

  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  /* or 164% */

  display: flex;
  align-items: flex-end;
  text-align: justify;

  color: #787878;
}

.alertModalBtn {
  margin-top: 3.5rem;
  /* 
font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 16px;
text-align: center;
letter-spacing: 1px;
text-transform: capitalize;

color: #FFFFFF; */
}

.autenticaRegEtapas {
  margin-top: 2rem;
  padding-bottom: 1rem;
  /* bottom: 2rem; */
}
</style>