<template>
  <div class="autenticaCodigoInputComp">
    <div class="numeric_input">
      <div>
        <input
          autocomplete="off"
          id="codigoUno"
          type="number"
          min="0"
          max="9"
          v-model="codigo1"
          @focus="limpiar(1)"
          :disabled="procesando6"
        />
      </div>
      <!-- @keypress="bloqueo(1)" 
      @keyup="saltar1" -->
      <div>
        <input
          autocomplete="off"
          id="codigoDos"
          type="number"
          min="0"
          max="9"
          v-model="codigo2"
          @focus="limpiar(2)"
          :disabled="procesando6"
        />
      </div>
      <!-- @keyup="saltar2" -->
      <div>
        <input
          autocomplete="off"
          id="codigoTres"
          type="number"
          min="0"
          max="9"
          v-model="codigo3"
          @focus="limpiar(3)"
          :disabled="procesando6"
        />
      </div>
      <!-- @keyup="saltar3" -->
      <div>
        <input
          id="codigoCuatro"
          autocomplete="off"
          type="number"
          min="0"
          max="9"
          v-model="codigo4"
          @focus="limpiar(4)"
          :disabled="procesando6"
        />
      </div>
      <!-- @keyup="saltar4" -->

      <div>
        <input
          autocomplete="off"
          id="codigoCinco"
          type="number"
          min="0"
          max="9"
          v-model="codigo5"
          @focus="limpiar(5)"
          :disabled="procesando6"
        />
      </div>
      <!-- @keyup="saltar5" -->
      <div>
        <input
          id="codigoSeis"
          autocomplete="off"
          type="number"
          min="0"
          max="9"
          v-model="codigo6"
          @focus="limpiar(6)"
          :disabled="procesando6"
        />
      </div>
      <!-- @keyup="saltar6" -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    borrarDatos: { type: Boolean },
    liberar_campos: Boolean,
  },
  data() {
    return {
      codigo1: "",
      codigo2: "",
      codigo3: "",
      codigo4: "",
      codigo5: "",
      codigo6: "",
      tieneError: false,
      procesando6: false,
      // bloqueo1:false,
    };
  },
  computed: {},
  methods: {
    // saltar1() {
    //   this.bloqueo(1);
    //   console.log(this.codigo1);
    //   this.tieneError = false;
    //   let cod2 = document.getElementById("codigoDos");
    //     if (cod2) cod2.focus();
    // },
    // saltar2() {
    //   this.tieneError = false;
    //   let cod3 = document.getElementById("codigoTres");
    //     if (cod3) cod3.focus();
    // },
    // saltar3() {
    //   this.tieneError = false;
    //   let cod4 = document.getElementById("codigoCuatro");
    //     if (cod4) cod4.focus();
    // },
    // saltar4() {
    //   this.tieneError = false;
    //   let cod5 = document.getElementById("codigoCinco");
    //     if (cod5) cod5.focus();
    // },
    // saltar5() {
    //   this.tieneError = false;
    //   let cod6 = document.getElementById("codigoSeis");
    //     if (cod6) cod6.focus();
    // },
    saltar6() {
      // console.log("saltar6");
      // if(this.procesando6){

      //   console.log(this.codigo6);
      //   return;
      // }

      this.tieneError = false;
      if (this.codigo6.toString() != "") {
        let codigo = "";

        if (this.codigo1.toString() != "") {
          codigo += this.codigo1.toString();
        } else {
          this.codigo1 = "";
          document.getElementById("codigoUno").focus();
          this.tieneError = true;
          return;
        }
        if (this.codigo2.toString() != "") {
          codigo += this.codigo2.toString();
        } else {
          this.codigo2 = "";
          document.getElementById("codigoDos").focus();
          this.tieneError = true;
          return;
        }
        if (this.codigo3.toString() != "") {
          codigo += this.codigo3.toString();
        } else {
          this.codigo3 = "";
          document.getElementById("codigoTres").focus();
          this.tieneError = true;
          return;
        }
        if (this.codigo4.toString() != "") {
          codigo += this.codigo4.toString();
        } else {
          this.codigo4 = "";
          document.getElementById("codigoCuatro").focus();
          this.tieneError = true;
          return;
        }

        if (this.codigo5.toString() != "") {
          codigo += this.codigo5.toString();
        } else {
          this.codigo5 = "";
          document.getElementById("codigoCinco").focus();
          this.tieneError = true;
          return;
        }
        if (this.codigo6.toString() != "") {
          codigo += this.codigo6.toString();
        } else {
          this.codigo6 = "";
          document.getElementById("codigoSeis").focus();
          this.tieneError = true;
          return;
        }

        // console.log("saltar4()", codigo);
        if (!this.tieneError) {
          this.procesando6 = true;
          this.$emit("revisarCodigoComp", codigo);
          // this.procesando6 = false;
        } else {
          this.$emit("tieneError", this.tieneError);
        }
      }

      // else if (this.codigo6.trim().length > 1) {
      //   this.codigo6 = this.codigo6.trim()[1];
      //   this.saltar6();
      // }
    },
    limpiar(dig) {
      if (dig == 1) {
        this.codigo1 = "";
      } else if (dig == 2) {
        this.codigo2 = "";
      } else if (dig == 3) {
        this.codigo3 = "";
      } else if (dig == 4) {
        this.codigo4 = "";
      } else if (dig == 5) {
        this.codigo5 = "";
      } else if (dig == 6) {
        this.codigo6 = "";
      }
    },
    bloqueo(posi) {
      if (posi == 1) {
        this.bloqueo1 = true;
        setTimeout(() => {
          this.bloqueo1 = false;
        }, 100);
      }
    },
  },
  watch: {
    tieneError() {
      this.$emit("tieneError", this.tieneError);
    },
    borrarDatos() {
      this.codigoUno = "";
      this.codigo2 = "";
      this.codigo3 = "";
      this.codigo4 = "";
      this.codigo5 = "";
      this.codigo6 = "";
      this.tieneError = false;
    },
    liberar_campos() {
      if (this.liberar_campos) {
        this.procesando6 = false;
      }
    },
    codigo1() {
      let aux = this.codigo1.toString();
      if (aux.trim().length > 1) {
        this.codigo1 = aux.trim()[0];
      }
      if (aux.trim().length == 1) {
        let cod2 = document.getElementById("codigoDos");
        if (cod2) cod2.focus();
      }
    },
    codigo2() {
      let aux = this.codigo2.toString();
      if (aux.trim().length > 1) {
        this.codigo2 = aux.trim()[0];
      }
      if (aux.trim().length == 1) {
        let cod3 = document.getElementById("codigoTres");
        if (cod3) cod3.focus();
      }
    },
    codigo3() {
      let aux = this.codigo3.toString();
      if (aux.trim().length > 1) {
        this.codigo3 = aux.trim()[0];
      }
      if (aux.trim().length == 1) {
        let cod4 = document.getElementById("codigoCuatro");
        if (cod4) cod4.focus();
      }
    },
    codigo4() {
      let aux = this.codigo4.toString();
      if (aux.trim().length > 1) {
        this.codigo4 = aux.trim()[0];
      }
      if (aux.trim().length == 1) {
        let cod5 = document.getElementById("codigoCinco");
        if (cod5) cod5.focus();
      }
    },
    codigo5() {
      let aux = this.codigo5.toString();
      if (aux.trim().length > 1) {
        this.codigo5 = aux.trim()[0];
      }
      if (aux.trim().length == 1) {
        let cod6 = document.getElementById("codigoSeis");
        if (cod6) cod6.focus();
      }
    },
    codigo6() {
      let aux = this.codigo6.toString();
      if (aux.trim().length > 1) {
        this.codigo6 = aux.trim()[0];
      }
      if (aux.trim().length == 1) {
        this.saltar6();
      }
    },
  },
};
</script>

<style scoped>
.autenticaCodigoInputComp {
  margin-top: 1rem;
  font-size: 0.823rem;
  font-weight: 700;
  line-height: 16.41px;
  color: var(--customA-801B1C);
  align-content: center;
  /* border:  1px solid red; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-content: center; */
  align-items: center;
}
.autenticaCodigoInputComp > div {
  display: flex;
  width: fit-content;
  /* border:  1px solid green; */
}
.autenticaCodigoInputComp > div > div {
  width: 2rem;
  border-bottom: 1.4px solid var(--customG-787878);
  margin: 0 0.2rem 0 0.2rem;
  display: flex;
  justify-content: center;
  /* border: 1px solid blue; */
}
.autenticaCodigoInputComp > div > div > input {
  font-size: 2rem !important;
  font-weight: 700 !important;
  line-height: 28.13px !important;
  color: var(--customC-614AD3) !important;
  width: 2rem !important;
  padding-left: 0.3rem !important;
}
.autenticaCodigoInputComp input {
  border: none;
}
.autenticaCodigoInputComp input:focus {
  outline: none;
}
/* .inputCodigoComp{
    font-size: 2rem;
  font-weight: 700;
  line-height: 28.13px;
  color: var(--customC-614AD3);
  width: 2rem;
  padding-left: 0.3rem;
} */
</style>